import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import { faSignOut } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import { ChangePasswordModal, RegisterModal, AlertLogout } from "../component";
function CustomNavbar(props) {
  const [showModalPassword, setModalPassword] = useState(false);
  const [showModalRegister, setModalRegister] = useState(false);
  const [showModalLogout, setModalLogout] = useState(false);
  function close() {
    sessionStorage.setItem("alertSuccess", "false");
    setModalRegister(false);
    setModalPassword(false);
  }
  return (
    <>
      <Navbar style={{ backgroundColor: "#024f86", height: "40px"}} variant="light">
        <Container>
          <Navbar.Brand className="title">Kusa Web</Navbar.Brand>
        </Container>
        <Nav>
          <Nav.Link className="navbar-title nav-register" onClick={() => setModalRegister(true)}>
            Register
          </Nav.Link>
          <Nav.Link className="navbar-title nav-change" onClick={() => setModalPassword(true)}>
            Change Password
          </Nav.Link>
          <Nav.Link onClick={() => setModalLogout(true)} className="navbar-title nav-logout">
            Logout <FontAwesomeIcon icon={faSignOut} size="1x" />
          </Nav.Link>
        </Nav>
      </Navbar>
      <ChangePasswordModal show={showModalPassword} close={() => setModalPassword(false)} closed={() => close()} />
      <RegisterModal show={showModalRegister} close={() => setModalRegister(false)} closed={() => close()} />
      <AlertLogout show={showModalLogout} close={() => setModalLogout(false)} />
    </>
  );
}

export default CustomNavbar;
