import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";

export default function AlertSuccess(props) {
  return (
    <>
      <Modal show={props.show}>
        <Modal.Header style={{ backgroundColor: "#72d542" }}>
          <Modal.Title>Success Message</Modal.Title>
        </Modal.Header>
        <Modal.Body>{props.message}</Modal.Body>
        <Modal.Footer>
          <Button style={{ backgroundColor: "#024f86" }} onClick={props.close}>
            OK
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
