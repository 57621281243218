import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";

const InputFormLogin = (props) => {
  const [isShow, setIsShow] = useState(true);
  return (
    <div className="form-group row">
      <label className={props.sizeLabel ?? "col-3 col-form-label"}>
        {props.title}
        {props.stars ? <span className="required-star">*</span> : null}
      </label>
      <div className="col-7">
        <div className="input-group">
          <input
            type={props.eye === true ? (isShow ? "password" : "text") : props.type}
            value={props.value}
            min={props.min}
            max={props.max}
            className="form-control"
            placeholder={props.placeholder}
            onChange={props.onChange}
            required={props.required}
            readOnly={props.readOnly}
          />
          {props.second ? (
            <div className="input-group-append">
              <span className="input-group-text d-flex justify-content-center" id="basic-addon2">
                Second
              </span>
            </div>
          ) : null}
        </div>
      </div>
      {props.eye === true && (
        <label className={"col-1 col-form-label"} onClick={() => setIsShow(!isShow)}>
          <FontAwesomeIcon icon={isShow ? faEyeSlash : faEye} size="1x" />
        </label>
      )}
    </div>

  );
}

export default InputFormLogin