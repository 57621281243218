import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { InputForm, AlertFailure, AlertSuccess, LoadingSpinner } from "../component";
import React, { useState, useEffect } from "react";
import axios from "axios";
export default function RegisterModal(props) {
  useEffect(() => {
    sessionStorage.setItem("alertSuccess", "false");
  });
  const alertSuccess = sessionStorage.getItem("alertSuccess");
  const [message, setMessage] = useState("");
  const [alertFailed, setAllertFailed] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [form, setForm] = useState({
    username: "",
    password: "",
    confirm_password: "",
  });
  const onChangeForm = (name, value) => {
    setForm({
      ...form,
      [name]: value,
    });
  };
  function handleRegister(e) {
    e.preventDefault();
    setLoading(true);
    axios({
      method: "post",
      url: "https://be.kusaweb5555.com/api/register",
      data: form,
      headers: { "Content-Type": "multipart/form-data" },
    })
      .then((response) => {
        setMessage(response.data.message);
        if (response.data.message === "Registration Success !") {
          sessionStorage.setItem("alertSuccess", "true");
          setLoading(false);
        } else {
          setAllertFailed(true);
          setLoading(false);
        }
      })
      .catch((error) => {
        setMessage(error);
        console.error("data Error!", error);
        setAllertFailed(true);
        setLoading(false);
      });
  }

  return (
    <>
      <AlertFailure show={alertFailed} close={() => setAllertFailed(false)} message={message} />
      <AlertSuccess show={alertSuccess === "true" ? true : false} close={props.closed} message={message} />
      <Modal show={props.show} size="lg">
        <Modal.Header className="header-modal">
          <Modal.Title>Register</Modal.Title>
        </Modal.Header>
        {isLoading === true ? (
          <Modal.Body>
            <LoadingSpinner />
          </Modal.Body>
        ) : (
          <form onSubmit={handleRegister}>
            <Modal.Body>
              <InputForm sizeLabel="col-4 col-form-label" type="text" title="Username" placeholder="Username" stars onChange={(e) => onChangeForm("username", e.target.value)} required={true} />
              <InputForm sizeLabel="col-4 col-form-label" type="password" title="Password" placeholder="Password" stars onChange={(e) => onChangeForm("password", e.target.value)} required={true} eye={true} />
              <InputForm sizeLabel="col-4 col-form-label" type="password" title="Confirm Password" placeholder="Confirm Password" stars onChange={(e) => onChangeForm("confirm_password", e.target.value)} required={true} eye={true} />
            </Modal.Body>
            <Modal.Footer>
              <Button variant="danger" onClick={props.close}>
                Close
              </Button>
              <Button className="primary-color" type="submit">
                Save Changes
              </Button>
            </Modal.Footer>
          </form>
        )}
      </Modal>
    </>
  );
}
