import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";

export default function AlertFailure(props) {
  return (
    <>
      <Modal show={props.show}>
        <Modal.Header style={{ backgroundColor: "red" }}>
          <Modal.Title style={{ color: "white" }}>Failed Message</Modal.Title>
        </Modal.Header>
        <Modal.Body>{props.message}</Modal.Body>
        <Modal.Footer>
          <Button className="primary-color" onClick={props.close}>
            OK
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
