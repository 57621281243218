import { useNavigate } from "react-router-dom";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
export default function AlertLogout(props) {
  const navigate = useNavigate();
  function logout() {
    localStorage.removeItem("user");
    navigate("/");
  }

  var user = localStorage.getItem("user");
  return (
    <>
      <Modal show={props.show}>
        <Modal.Header className="header-modal ">
          <Modal.Title>Logout</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div style={{ fontSize: 25, color: "#72d542" }}>Hi {user}</div>
          Do you want to logout ?
        </Modal.Body>
        <Modal.Footer>
          <Button variant="danger" onClick={props.close}>
            Cancel
          </Button>
          <Button className="primary-color" onClick={() => logout()}>
            OK
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
