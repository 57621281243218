export default function InputForm2(props) {
  
  return (
    <div className="custom-form">
      <div className="inner-form1-2">
        {props.title} 
      </div>
      <div className={"inner-form2-2-"+props.color}>
        <div className="inside-form2-1">
          <input 
            className="input-text2" 
            type={props.type}
            value={props.value}
            min="0"
            placeholder={props.placeholder}
            onChange={props.onChange}
            required={props.required}
            // readOnly={props.readOnly}
          />
        </div>
        <div className="inside-form2-2">
          Second
        </div>
      </div>
    </div> 
  );
}
