import { Fragment } from "react";

export default function LoadingSpinner() {
  return (
    <Fragment>
      <div className="spinner-container">
        <div className="loading-spinner"></div>
      </div>
    </Fragment>
  );
}
