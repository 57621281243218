import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
export default function InputForm(props) {
  const [isShow, setIsShow] = useState(true);
  // return (
  //   <div className="form-group row no-gutters align-items-center mr-3">
  //     <label className="align-items-center mr-3">
  //       {props.title}
  //       {props.stars ? <span className="required-star">*</span> : null}
  //     </label>
  //     <div>
  //       <div className="input-group">
  //         <input
  //           type={props.eye === true ? (isShow ? "password" : "text") : props.type}
  //           value={props.value}
  //           min={props.min}
  //           max={props.max}
  //           className="form-control test-form"
  //           placeholder={props.placeholder}
  //           onChange={props.onChange}
  //           required={props.required}
  //           readOnly={props.readOnly}
  //           style={{width:props.width}}
  //         />
  //         {props.second ? (
  //           <div className="input-group-append">
  //             <span className="input-group-text d-flex justify-content-center" id="basic-addon2">
  //               Second
  //             </span>
  //           </div>
  //         ) : null}
  //           {props.secondBlack ? (
  //           <div className="input-group-append">
  //             <span className="input-group-text d-flex justify-content-center" id="basic-addon3">
  //               Second
  //             </span>
  //           </div>
  //         ) : null}
  //       </div>
  //     </div>

  //     {props.eye === true && (
  //       <label className={"col-1 col-form-label"} onClick={() => setIsShow(!isShow)}>
  //         <FontAwesomeIcon icon={isShow ? faEyeSlash : faEye} size="1x" />
  //       </label>
  //     )}
  //   </div>
  // );
 
  
  return (
    <div className="custom-form">
      <div className="inner-form1">
        {props.title} {props.star ? <span className="required-star">*</span> : null}
      </div>
      <div className="inner-form2">
        <input 
          className="input-text"
          onChange={props.onChange}
          maxLength={props.maxLength}
          type={props.type}
          value={props.value}
          placeholder={props.placeholder}
          required={props.required}
        />
      </div>
    </div> 
  );
}
