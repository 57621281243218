import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Fragment } from "react";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import React, { useState, useEffect } from "react";
import ReactPlayer from "react-player";
import axios from "axios";
import { InputForm, LoadingSpinner, NoData, CustomNavbar } from "../component";
import { Navigate } from "react-router-dom";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Filler,
  Legend,
} from 'chart.js';
import { Line } from 'react-chartjs-2';
import InputForm2 from "../component/input_form2";
import convert from "../utils/time-converter";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Filler,
  Legend
);

export default function HomePage() {
  // screen height
  const [browserHeight, setBrowserHeight] = useState(window.innerHeight) 
  const [browserWidth, setBrowserWidth] = useState(window.innerWidth) 
  //chart
  const [labels, setLabels] = useState([]);
  const [countChart, setCountChart] = useState([]);
  const [titleChart, setTitleChart] = useState();
  //init state
  const playerRef = React.useRef();
  const [isPlaying, setIsPlaying] = useState(true);
  const [isRef, setIsRef] = useState(false);
  const [data, setData] = useState([]);
  const [nestedData, setNestedData] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const [seekColor, setSeekColor] = useState();
  const [messageColor, setMessageColor] = useState([]);

  var [isSeekArr, setSeekArr] = useState(0);
  const [isId, setId] = useState(0);
  const [isKeywords, setKeywords] = useState("");

  const [user, setUser] = useState(localStorage.getItem("user"))
  const [form, setForm] = useState({
    link: "",
    start: "0",
    end: "3600",
    interval: "60",
    keywords: "lol",
  });
  function linkGenerator(link) {
    if (link.includes("live") || link.includes("feature=share")) {
      const pattern = /\/([a-zA-Z0-9_-]+)\?/;
      const rawData = link.match(pattern);
      const baseURL = "https://www.youtube.com/watch?v=";
      const res = `${baseURL}${rawData[1]}`;
      return res;
    } else {
      return link;
    }
  }
  //set target form
  const onChangeForm = (name, value) => {
    setForm({
      ...form,
      [name]: value,
    });
  };

  //fetch data master
  function fetchData(e) {
    var newLabels = [];
    var newCountChart = [];
    e.preventDefault();
    setLoading(true);
    setSeekColor(null);
    setMessageColor(null);
    if (form.start === "") {
      form.start = 0;
    }
    if (form.end === "") {
      form.end = 0;
    }
    axios({
      method: "post",
      url: "https://be.kusaweb5555.com/api/search",
      data: form,
      headers: { "Content-Type": "multipart/form-data" },
    })
      .then((response) => {
        // console.log(response.data.data.filter_result)
        setData(response.data.data.filter_result);
        setNestedData(response.data.data.all_chat);
        for (let i = 0; i < response.data.data.filter_result.length; i++) {
          // newLabels.push(new Date(response.data.data.filter_result[i].low * 1000).toISOString().slice(11, 19) + " - " + new Date(response.data.data.filter_result[i].up * 1000).toISOString().slice(11, 19));
          const time = convert(response.data.data.filter_result[i].up) 
          // newLabels.push(response.data.data.filter_result[i].up);
          newLabels.push(time);
          // console.log('up')
          // console.log(response.data.data.filter_result[i].up)
          // console.log(time)
          newCountChart.push(response.data.data.filter_result[i].count);
        }
        setLabels(newLabels);
        setCountChart(newCountChart);
        setTitleChart(response.data.keywords[0]);

        setKeywords(form.keywords);
        // console.log(response.data.keywords);

        setLoading(false);
      })
      .catch((error) => {
        console.error("data Error!", error);
        setLoading(false);
      });
  }
  // console.log(data)
  // console.log(nestedData)
  //New Seek
  // const Seek = async (filter_result) => {
  //   await playerRef.current.seekTo(filter_result, "seconds");
  //   setIsPlaying(false);
  //   setIsRef(true);
  // };

  // old
  const Seek = async (seekLength, timeSeconds, id, index) => {
    // console.log("seek")
    setId(id);
    if (id == isId) {
      if (seekLength - 1 > isSeekArr) {
        setSeekArr(++isSeekArr);
      } else {
        setSeekArr((isSeekArr = 0));
      }
    } else {
      setSeekArr((isSeekArr = 0));
    }
    const timeToStart = timeSeconds[isSeekArr].time_in_seconds;
    await playerRef.current.seekTo(timeToStart, "seconds");

    setMessageColor(timeSeconds[isSeekArr].time + timeSeconds[isSeekArr].message);
    setSeekColor(index)
    goToSeek(timeSeconds[isSeekArr].time + timeSeconds[isSeekArr].message)

    setIsPlaying(true);
    setIsRef(true);
  };
  
  const SeekGraph = async (seekLength, timeSeconds, id, index) => {
    console.log("seekGraph")
    setId(id);
    if (id == isId) {
      if (seekLength - 1 > isSeekArr) {
        setSeekArr(++isSeekArr);
      } else {
        setSeekArr((isSeekArr = 0));
      }
    } else {
      setSeekArr((isSeekArr = 0));
    }
    const timeToStart = timeSeconds[isSeekArr].time_in_seconds;
    await playerRef.current.seekTo(timeToStart, "seconds");
    goToSeek("timestamp-"+index)
    setSeekColor(index)

    setIsPlaying(true);
    setIsRef(true);

    setTimeout(()=>{
      Seek(seekLength, timeSeconds, id, index)
      // setMessageColor(timeSeconds[isSeekArr].time + timeSeconds[isSeekArr].message);
      // goToSeekGraph(timeSeconds[isSeekArr].time + timeSeconds[isSeekArr].message)
    },100)

  };
  const SeekView = async (seekLength, timeSeconds, id, index) => {
    // console.log("seek")
    setId(id);
    if (id == isId) {
      if (seekLength - 1 > isSeekArr) {
        setSeekArr(++isSeekArr);
      } else {
        setSeekArr((isSeekArr = 0));
      }
    } else {
      setSeekArr((isSeekArr = 0));
    }

    setMessageColor(timeSeconds[isSeekArr].time + timeSeconds[isSeekArr].message);
    setSeekColor(index)
    goToSeek(timeSeconds[isSeekArr].time + timeSeconds[isSeekArr].message)

    // setIsPlaying(true);
    // setIsRef(true);
  };
  const SeekEmptyMessageView = async (index) => {
    console.log("seek empty view mesasge")
    setSeekColor(index)
  };
  const SeekEmptyMessage = async (timeLow, index) => {
    console.log("seek empty message")
    
    await playerRef.current.seekTo(timeLow, "seconds");

    goToSeek("timestamp-"+index)
    setSeekColor(index)

    setIsPlaying(true);
    setIsRef(true);
  };
  
  useEffect(()=>{
    console.log("render on home")
    // checkUser()
    const handleResize = () => {
      setBrowserHeight(window.innerHeight);
      setBrowserWidth(window.innerWidth);
    };

    // Add event listener for window resize
    window.addEventListener('resize', handleResize);

    // Remove event listener when the component is unmounted
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  // },[user])
  },[])

  if (!user) {
    console.log("not found user")
    return <Navigate to="/" replace />;
  }

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        position: 'top',
      },
      title: {
        display: true,
        // text: 'Search Terms Chart',
      },
    },
    scales:{
      y:{
        ticks: { stepSize: 1 },
        min: 0,
      }
    },
    interaction:{
      mode: 'point'
    },
    elements:{
      point:{
        // radius: 9,
        hitRadius: 9
      }
    }, 
    onClick: (event, elements) => {
      console.log(elements.length)
      if (elements.length > 0) {
        console.log('click')
        const index = elements[0].index;
        // Seek(data[index].low);
        // if(data[index].count !== 0){
        //   setSeekColor(index);
        //   setMessageColor(data[index].match_chat[0].time + data[index].match_chat[0].message);
        //   goToSeek(data[index].match_chat[0].time + data[index].match_chat[0].message);
        // }
        if(data[index].count !== 0){
          SeekGraph(data[index].match_chat.length, data[index].match_chat, data[index].id,index) 
        }else{
          if(data[index].all_chat.length==0){
            SeekEmptyMessage(data[index].low, index)
          }else{
            SeekGraph(data[index].all_chat.length, data[index].all_chat, data[index].id,index)
          }
        }
      }
    },
  };

  const dataChart = {
    labels,
    datasets: [
      {
        fill: true,
        label: titleChart ?? "",
        data: countChart,
        borderColor: 'rgb(53, 162, 235)',
        backgroundColor: 'rgba(53, 162, 235, 0.5)',
        tension: 0.2
      },
    ],
  };

  //going to seek
  const goToSeek=(id)=>{
    const targetElement = document.getElementById(id);
    if (targetElement) {
      // targetElement.scrollIntoView({ behavior: 'smooth', block: 'start'});
      targetElement.scrollIntoView({ behavior: 'instant', block: 'start'});
    }
  }

  const goToSeekGraph=(id)=>{
    const targetElementMessage = document.getElementById(id);
    if (targetElementMessage) {
      targetElementMessage.scrollIntoView({ behavior: 'instant' });
    }
  }
  const tableHeight = (browserHeight, browserWidth)=>{
    let res = 0
    if(browserWidth >= 1250){
      res = browserHeight - 550
    }else if(browserWidth >=750){
      res = browserHeight - 400
    }else{
      res = browserHeight - 300
    }
    return res
  }
  // console.log(`${tableHeight(browserHeight, browserWidth)}px`)
  return (
    <Fragment>
      <CustomNavbar />
        {/* <div className="container-fluid"> */}
        {/* input header */}
          <form className="form-input-container" onSubmit={fetchData}>
              <div className="input-side">
                <InputForm 
                type="text" 
                title="Youtube Link" 
                value={form.link ?? ""} 
                onChange={(e) => onChangeForm("link", linkGenerator(e.target.value))} 
                star 
                required/>

                <InputForm 
                type="text" 
                title="Search Terms" 
                value={form.keywords} 
                maxLength={6} 
                onChange={(e) => onChangeForm("keywords", e.target.value)} 
                star 
                required />

                <InputForm2 
                type="number" 
                title="Interval" 
                stars 
                value={form.interval} 
                onChange={(e) => onChangeForm("interval", e.target.value.slice(0, 6))}
                color="grey"/>

                <InputForm2 
                type="number" 
                title="Start Time" 
                value={form.start} 
                onChange={(e) => onChangeForm("start", e.target.value.slice(0, 6))}
                color="grey"/>

                <InputForm2 
                type="number" 
                title="End Time" 
                value={form.end} 
                onChange={(e) => onChangeForm("end", e.target.value.slice(0, 6))}
                color="blue"/>
              </div>

              <div className="button-side">
                <button className="search-button" type="submit">
                  <FontAwesomeIcon icon={faSearch} size="1x" /> SEARCH
                </button>
              </div>
          </form>

        {/* divider line */}
        <div className="line-box"></div>

        {/* video and graph */}
        <div className="container-fluid mt-2 mb-2">
          <div className="row">
            <div className="col-6 d-flex justify-content-center video-container" id="section1">
              {/* <ReactPlayer ref={playerRef} url={form.link} playing={isPlaying} controls={true} width={650} height={365} /> */}
              <ReactPlayer ref={playerRef} url={form.link} playing={isPlaying} controls={true} width={1000} height={"100%"}/>
            </div>

              {/* scrollable chart */}
            {/* <div className="col-6 chart-container"> */}
            {/* <div className="col-6 chart-container">
              <div className="chartWrapper">
              {isLoading === true ?  
                <LoadingSpinner /> :  
                <Line options={options} data={dataChart} className="chart"/>
              }
              </div> */}
              {/* end scrollable chart */}

            <div className="col-6">
              {isLoading === true ?  
                  <LoadingSpinner /> :  
                <Line options={options} data={dataChart} className="chart"/>
              }

            </div> 
          </div>
        </div>

        {/* time stamp and comment */}
        <div className="col-12">
          <div className="row ">
            <div className="col-sm-6 col-md-6 col-lg-5 col-xl-4">
              <div className="table-responsive">
              <table className="table">
                <thead className="thead-light" >
                  <tr >
                    <th className="text-center timestamp-field" >Timestamp</th>
                    <th className="text-center count-field" >Count</th>
                    <th className="text-center action-field" >Action</th>
                  </tr>
                </thead>
                {/* <tbody style={ browserWidth < 1250 ? {height:`calc(${browserHeight}px - 350px)`} : {height:`calc(${browserHeight}px - 250px)`} }> */}
                <tbody style={{height:`${tableHeight(browserHeight,browserWidth)}px`}}>
                  {isLoading === true ? (
                    <tr>
                      <td>
                        <LoadingSpinner />
                      </td>
                    </tr>
                  ) : data.length !== 0 ? (
                    data.map((item, index) => (
                      <tr id={"timestamp-"+index}>
                        {/* timestamp */}
                        <td className="left-table timestamp-field" >
                        {/* <td className="left-table"> */}
                          {index === seekColor ? 
                          <span className="seek-badge text-center align-middle">
                            {new Date(item.low * 1000).toISOString().slice(11, 19)} - {new Date(item.up * 1000).toISOString().slice(11, 19)}
                          </span> : 
                          <span className="text-seek">
                            {new Date(item.low * 1000).toISOString().slice(11, 19)} - {new Date(item.up * 1000).toISOString().slice(11, 19)}
                          </span>}
                        </td>
                        {/* count */}
                        <td className="text-center left-table align-middle count-field">
                          {item.count !== 0 ? (
                            <div className="count-badge ">
                              <span className="align-middle">{item.count}</span>
                            </div>
                          ) : (
                             <div className="count-badge ">
                              <span className="align-middle">{item.count}</span>
                            </div>
                          )}
                        </td>
                        {/* seek */}
                        <td className="text-center left-table align-middle action-field" >
                          <div className="action-wrapper">
                            <button className="btn-view" onClick={() =>{ 
                                if(item.count !== 0){
                                  // console.log("match not 0")
                                  SeekView(item.match_chat.length, item.match_chat, item.id,index) 
                                }else{
                                  // console.log("no match chat")
                                  if(item.all_chat.length==0){
                                    // console.log("no message")
                                    // SeekEmptyMessage(item.low, index)
                                    SeekEmptyMessageView(index)
                                  }else{
                                    // console.log("there are some message")
                                    SeekView(item.all_chat.length, item.all_chat, item.id, index) 
                                  }
                                }
                            }}>
                              View
                            </button>
                            <button className="btn-seek" onClick={() =>{ 
                                if(item.count !== 0){
                                  Seek(item.match_chat.length, item.match_chat, item.id,index) 
                                }else{
                                  if(item.all_chat.length==0){
                                    SeekEmptyMessage(item.low, index)
                                  }else{
                                    Seek(item.all_chat.length, item.all_chat, item.id, index) 
                                  }
                                }
                              }
                            }>
                              Seek
                            </button>
                          </div>
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td>
                        <NoData />
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
              </div>
            </div>

            <div className="col-sm-6 col-md-6 col-lg-7 col-xl-8">
              <table className="table">
                <thead className="thead-light">
                  <tr >
                    <th className="time-size" scope="col">
                      Time
                    </th>
                    <th scope="col">Message</th>
                  </tr>
                </thead>
                <tbody style={{height:`${tableHeight(browserHeight,browserWidth)}px`}}>
                {/* <tbody style={ browserWidth < 1250 ? {height:`calc(${browserHeight}px - 350px)`} : {height:`calc(${browserHeight}px - 250px)`} }> */}
                  {isLoading === true ? (
                    <LoadingSpinner />
                  ) : nestedData.length === 0 ? (
                    <tr>
                      <td>
                        <NoData />
                      </td>
                    </tr>
                  ) : (
                    nestedData.map((item, index) => (
                      <tr id={item.time + item.message}>
                        <td className="time-size">{item.time}</td>
                        {messageColor === item.time + item.message?<td className="teks-break seek-message">{item.message}</td>  :      <td className="teks-break">{item.message}</td>}
                      </tr>
                    ))
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
    </Fragment>
  );
}
