const convert = (data)=>{
    let res
    if(data < 60){
        res = "00:"+data       
    }else if(data < 3600){
        // mod
        if(data % 60 == 0){
            const minute =(data/60) 
            res = minute + ":00"
        }else{
            const minute =(Math.floor(data/60)) 
            const second =(data%60) 
            if(second < 10){
                res = minute + ":0" + second
            }else{
                res = minute + ":" + second
            }
        } 
    }else{
        // 3600 = 01:00:00
        // 3601 = 01:00:01
        // 3625 = 01:00:25
        // 3660 = 01:01:00
        // 3760 = 01:01:00

        if(data % 3600 == 0){
            const hour =(data/3600) 
            res = hour + ":00:00"
        }else{
            const hour =(Math.floor(data/3600)) 
            const tempMinute =(data%3600)
            if(tempMinute % 60 == 0){
                const minute =(tempMinute/60) 
                console.log('here1')
                res = hour + ":"+minute+":00"
            }else{
                const minute =(Math.floor(tempMinute/60)) 
                const second =(tempMinute%60) 
                if(second < 10){
                console.log('here2')
                    res = hour + ":0" + minute + ":0" + second
                }else{
                console.log('here3')
                    res = hour + ":0" + minute + ":" + second
                }
            } 
        }
    }
    return res
}

// const x = [10,720,3601,7225,3600,300,3900,4200,70,1810]
// for(const i in x){
//     console.log(`${x[i]}: ${convert(x[i])}`)
// }

export default convert