import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { InputForm, AlertSuccess, AlertFailure, LoadingSpinner } from "../component";
import React, { useState, useEffect } from "react";
import axios from "axios";
export default function ChangePasswordModal(props) {
  useEffect(() => {
    sessionStorage.setItem("alertSuccess", "false");
  });
  const alertSuccess = sessionStorage.getItem("alertSuccess");
  var user = localStorage.getItem("user");
  const [message, setMessage] = useState("");
  const [alertFailed, setAllertFailed] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [form, setForm] = useState({
    current_username: user,
    old_password: "",
    new_password: "",
    new_password_confirmation: "",
  });
  const onChangeForm = (name, value) => {
    setForm({
      ...form,
      [name]: value,
    });
  };

  function handleChangePassword(e) {
    e.preventDefault();
    setLoading(true);
    axios({
      method: "post",
      url: "https://be.kusaweb5555.com/api/changePassword",
      data: form,
      headers: { "Content-Type": "multipart/form-data" },
    })
      .then((response) => {
        setMessage(response.data.message);
        if (response.data.message === "Your Password is updated !") {
          sessionStorage.setItem("alertSuccess", "true");
          setLoading(false);
        } else {
          setAllertFailed(true);
          setLoading(false);
        }
      })
      .catch((error) => {
        setMessage(error);
        console.error("data Error!", error);
        setLoading(false);
        setAllertFailed(true);
      });
  }
  return (
    <>
      <AlertFailure show={alertFailed} close={() => setAllertFailed(false)} message={message} />
      <AlertSuccess show={alertSuccess === "true" ? true : false} close={props.closed} message={message} />
      <Modal show={props.show} size="lg">
        <Modal.Header className="header-modal ">
          <Modal.Title>Change Password</Modal.Title>
        </Modal.Header>

        {isLoading === true ? (
          <Modal.Body>
            <LoadingSpinner />
          </Modal.Body>
        ) : (
          <form onSubmit={handleChangePassword}>
            <Modal.Body>
              <InputForm sizeLabel="col-4 col-form-label" type="text" title="Username" stars value={user} required={true} readOnly={true} />
              <InputForm sizeLabel="col-4 col-form-label" type="password" title="Old Password" placeholder="Old Password" stars onChange={(e) => onChangeForm("old_password", e.target.value)} required={true} eye={true} />
              <InputForm sizeLabel="col-4 col-form-label" type="password" title="New Password" placeholder="New Password" stars onChange={(e) => onChangeForm("new_password", e.target.value)} required={true} eye={true} />
              <InputForm
                sizeLabel="col-4 col-form-label"
                type="password"
                title="Confirm Password"
                placeholder="Confirm Password"
                stars
                onChange={(e) => onChangeForm("new_password_confirmation", e.target.value)}
                required={true}
                eye={true}
              />
            </Modal.Body>
            <Modal.Footer>
              <Button variant="danger" onClick={props.close}>
                Close
              </Button>
              <Button className="primary-color" type="submit">
                Save Changes
              </Button>
            </Modal.Footer>
          </form>
        )}
      </Modal>
    </>
  );
}
