import { Fragment, useEffect } from "react";
import { LoadingSpinner, AlertFailure } from "../component";
import { Navigate, useNavigate } from "react-router-dom";
import { faSignIn } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import axios from "axios";
import InputFormLogin from "../component/input_form_login";
export default function LoginPage() {
  const [isLoading, setLoading] = useState(false);
  const [message, setMessage] = useState("");
  const [form, setForm] = useState({
    username: "",
    password: "",
  });
  const [alertFailed, setAllertFailed] = useState(false);
  //set target form
  const onChangeForm = (name, value) => {
    setForm({
      ...form,
      [name]: value,
    });
  };
  const navigate = useNavigate();
  const [user, setUser] = useState(localStorage.getItem("user"))

  function handleLogin(e) {
    e.preventDefault();
    setLoading(true);
    axios({
      method: "post",
      url: "https://be.kusaweb5555.com/api/login",
      data: form,
      headers: { "Content-Type": "multipart/form-data" },
    })
      .then((response) => {
        setMessage(response.data.message);
        setLoading(false);
        if (response.data.message === "Login success !") {
          navigate("/home");
          localStorage.setItem("user", form.username);
          setLoading(false);
        } else {
          setAllertFailed(true);
          setLoading(false);
        }
      })
      .catch((error) => {
        setMessage(error);
        console.error("data Error!", error);
        setAllertFailed(true);
        setLoading(false);
      });
  }

  useEffect(()=>{
    console.log("render on login")
  },[])
  
  if (user) {
    console.log("found user")
      return <Navigate to="/home" replace />;
  }
  console.log("not found user")

  return (
    <Fragment>
      {isLoading === true ? (
        <div style={{ position: "absolute", left: "50%", top: "40%", transform: "translate(-50%, -50%)" }}>
          <LoadingSpinner />
        </div>
      ) : (
        <>
          <AlertFailure show={alertFailed} close={() => setAllertFailed(false)} message={message} />
          <div className="container">
            <div className="d-flex justify-content-center">
              <div className="login-container" style={{ position: "absolute", top: "20%" }}>
                <div className="card ">
                  <div className="card-header text-center">KUSA WEB</div>
                  <div className="card-body">
                    <form onSubmit={handleLogin}>
                      <InputFormLogin type="text" title="Username" placeholder="Username" stars onChange={(e) => onChangeForm("username", e.target.value)} required={true} />
                      <InputFormLogin type="password" title="Password" placeholder="Password" stars onChange={(e) => onChangeForm("password", e.target.value)} required={true} eye={true} />
                      <button className="btn btn-login" style={{ width: "100%", height: 50 }} type="submit">
                        <FontAwesomeIcon icon={faSignIn} size="1x" style={{ marginRight: 10 }} />
                        Login
                      </button>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </Fragment>
  );
}
