import "./App.css";
import { HomePage,LoginPage } from "./pages";
import {
  BrowserRouter as Router,
  Routes,
  Route
 } from "react-router-dom";
function App() {
  return (
    <div>
     <Router basename="/">
      <Routes>
        <Route path="/" element={<LoginPage/>} />
        <Route path="/home" element={<HomePage/>} />
      </Routes>
    </Router>
    </div>
  );
}

export default App;
